import { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import { faHeart as faHeartSolid } from "@fortawesome/free-solid-svg-icons";
import { faTrash, faHome, faFolder, faExclamationTriangle, faInfoCircle, faRefresh, faChartGantt } from "@fortawesome/free-solid-svg-icons";

import CreateGoogleSheetButton from "../components/CreateGoogleSheetButton";
import GoogleButton from "react-google-button";

function ProjectDetail() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const modelType = queryParams.get("model");
  const mutationType = queryParams.get("mutation");

  const [isContentReady, setIsContentReady] = useState(false);

  const { projectId } = useParams();
  const [loading, setLoading] = useState(false);
  const [projectData, setProjectData] = useState(null);
  const [nameData, setNameData] = useState([]);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [manualButtonDisabled, setManualButtonDisabled] = useState(false);
  const [generateButtonDisabled, setGenerateButtonDisabled] = useState(false);
  const [disregardF, setDisregardF] = useState(true); // State for disregarding names with 'F' grade
  const [disregardSensitive, setdDisregardSensitive] = useState(true); // State for disregarding names with 'F' grade
  const [status, setStatus] = useState(null); // State for disregarding names with 'F' grade
  const [maxCharacter, setMaxCharacter] = useState(true); // State for filtering by maximum character length
  const [showPrecisionPopup, setShowPrecisionPopup] = useState(false);
  const [stage, setStage] = useState({
    create: true,
    generate: false,
    mutate: false,
    complete: false,
  });
  const host = process.env.REACT_APP_SERVER;
  const id = `${projectId} - scrollPosition`;

  const handleNameClick = () => {
    const scrollPosition = window.scrollY;
    localStorage.setItem(id, scrollPosition.toString());
    console.log("Scroll position saved:", scrollPosition);
  };

  useEffect(() => {
    const scrollPosition = parseInt(localStorage.getItem(id), 10);
    console.log("Scroll position loaded:", scrollPosition);

    if (!isNaN(scrollPosition)) {
      if (isContentReady) {
        console.log("scrolling down!");
        window.scrollTo(0, scrollPosition);
        console.log("Window Height:", window.innerHeight);
        console.log("New position:", window.scrollY);
        localStorage.removeItem(id); // Flush the scroll position after scrolling
      } else {
        // Content is not ready, wait and check again
        setTimeout(() => setIsContentReady(true), 500); // Adjust delay as needed
      }
    }
  }, [id, isContentReady]);

  useEffect(() => {
    const fetchNameData = async () => {
      setLoading(true);

      try {
        const maxCharsQuery = projectData && projectData.max_chars ? `?max_chars=${projectData.max_chars}` : "";
        const response = await fetch(`${host}/api/projects/${projectId}/names${maxCharsQuery}`);
        const jsonData = await response.json();

        const allNames = jsonData.data.names.slice(0);

        let filteredNameData = allNames.filter((name) => (modelType ? name.model === modelType : true));
        filteredNameData = filteredNameData.filter((name) => (mutationType ? name.mutations[-1] === mutationType : true));
        console.log(filteredNameData.length);
        setNameData(filteredNameData);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchNameData();
  }, [projectData]);

  useEffect(() => {
    const fetchProjectData = async () => {
      setLoading(true);

      try {
        const response = await fetch(`${host}/api/projects/${projectId}`);
        const jsonData = await response.json();
        setProjectData(jsonData.data.project);

        // Set the visual pipeline as true foth ese thines
        if (jsonData.data.project.status === "complete") {
          setStage({
            create: true,
            generate: true,
            mutate: true,
            complete: true,
          });
          setGenerateButtonDisabled(true);
        }
        if (jsonData.data.project.status === "generate") {
          setStage({
            create: true,
            generate: true,
            mutate: false,
            complete: false,
          });
          setGenerateButtonDisabled(true);
        }
        if (jsonData.data.project.status === "mutate") {
          setStage({
            create: true,
            generate: true,
            mutate: true,
            complete: false,
          });
          setGenerateButtonDisabled(true);
        }
        setStatus(jsonData.data.project.status);

        console.log("Project:", jsonData.data.project);
        if (jsonData.data.project.status == "Loading") {
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchProjectData();
  }, [projectId]);

  const togglePrecisionPopup = () => {
    setShowPrecisionPopup(!showPrecisionPopup);
  };

  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
  };

  const handleBookmarkToggle = async (name) => {
    const updatedName = { ...name, bookmarked: !name.bookmarked };
    try {
      const response = await fetch(`${host}/api/projects/${projectId}/names/${name.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ bookmarked: !name.bookmarked }),
      });
      if (response.ok) {
        // Update the state here
        const updatedNameData = nameData.map((n) => (n.id === updatedName.id ? updatedName : n));
        setNameData(updatedNameData);
      } else {
        console.error("Failed to update bookmark status");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleGenerateNames = () => {
    console.log("handleGenerateNames");
    try {
      setGenerateButtonDisabled(true); // Disable the button
      // setStatus("generate");
      // setStage({
      //   create: true,
      //   generate: true,
      //   mutate: false,
      //   complete: false,
      // });
      // Create an EventSource to listen for updates
      const eventSource = new EventSource(`${host}/api/projects/${projectId}/status`);

      eventSource.onmessage = (event) => {
        const data = JSON.parse(event.data);

        switch (data.type) {
          case "stageUpdate":
            setStage((prevStatusUpdates) => ({
              ...prevStatusUpdates,
              [data.stage]: true,
            }));
            setStatus(data.stage);
            break;
          case "nameUpdate":
            setNameData((prevNames) => [...prevNames, data.nameData]);
            break;
          default:
            console.log("Received unknown data type:", data);
            console.log(data.type);
        }
      };

      // Close the event source when you're done
      eventSource.addEventListener("end", () => {
        eventSource.close();
      });

      // Send a POST request to initiate the name generation
      fetch(`${host}/api/projects/${projectId}/generate_names`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }).then((response) => {
        if (!response.ok) {
          throw new Error("Failed to initiate name generation");
        }
        // Optionally, you can update the frontend status here as well
        // Update the frontend status
      });
    } catch (error) {
      console.error("Error:", error);
      setGenerateButtonDisabled(false); // Enable the button if there's an error
    }
  };

  const compareValues = (valueA, valueB) => {
    if (valueA === null) {
      return sortDirection === "asc" ? 1 : -1;
    }
    if (valueB === null) {
      return sortDirection === "asc" ? -1 : 1;
    }

    if (typeof valueA === "string" && typeof valueB === "string") {
      const trademarkOrder = ["A", "B", "C", "D", "F"];
      const indexA = trademarkOrder.indexOf(valueA);
      const indexB = trademarkOrder.indexOf(valueB);

      if (indexA !== -1 && indexB !== -1) {
        return sortDirection === "asc" ? indexA - indexB : indexB - indexA;
      } else if (indexA !== -1) {
        return sortDirection === "asc" ? -1 : 1;
      } else if (indexB !== -1) {
        return sortDirection === "asc" ? 1 : -1;
      } else {
        return sortDirection === "asc" ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
      }
    } else {
      return sortDirection === "asc" ? valueA - valueB : valueB - valueA;
    }
  };

  const handleDelete = async (project) => {
    const confirmDelete = window.confirm(`Are you sure you want to permanently DELETE this project? ${project.name}??`);

    if (confirmDelete) {
      try {
        const response = await fetch(`${host}/api/projects/${project.id}`, {
          method: "DELETE",
        });

        if (response.status === 204) {
          navigate("/projects");
        } else {
          // Handle error here, e.g., show an error message
          console.error("Failed to delete project");
        }
      } catch (error) {
        // Handle error here, e.g., show an error message
        console.error("Failed to delete project", error);
      }
    }
  };

  const fetchData = async () => {
    setLoading(true);

    try {
      const response = await fetch(`${host}/api/projects`);
      const jsonData = await response.json();
      jsonData.data.projects.sort((a, b) => b.id - a.id);
      setProjectData(jsonData.data.projects);
      console.log("jsonData.data.projects", jsonData.data.projects);
    } catch (error) {
      // Handle error here, e.g. console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleClear = async (projectId) => {
    const confirm = window.confirm("Are you sure you want to CLEAR all the names from this project?");

    if (confirm) {
      try {
        const response = await fetch(`${host}/api/projects/${projectId}/clear`, {
          method: "DELETE",
        });

        if (response.status === 204) {
          // Project successfully deleted, you can update the UI accordingly
          // fetchData(); // Refetch data to update the project list

          window.location.reload();
        } else {
          // Handle error here, e.g., show an error message
          console.error("Failed to delete project");
        }
      } catch (error) {
        // Handle error here, e.g., show an error message
        console.error("Failed to delete project", error);
      }
    }
  };

  // Function to toggle the Disregard F filter
  const toggleDisregardF = () => {
    setDisregardF((prev) => !prev);
  };

  // Function to toggle the Disregard F filter
  const toggleDisregardSensitivity = () => {
    setdDisregardSensitive((prev) => !prev);
  };

  // Function to toggle the Maximum Character filter
  const toggleMaxCharacter = () => {
    setMaxCharacter((prev) => !prev);
  };

  const sortedNameData = [...nameData].sort((a, b) => {
    if (sortColumn) {
      const valueA = a[sortColumn];
      const valueB = b[sortColumn];

      // Handle null values separately
      if (valueA === null && valueB !== null) {
        return 1; // Treat null as greater than non-null values
      } else if (valueA !== null && valueB === null) {
        return -1; // Treat null as lesser than non-null values
      }

      // If both values are non-null, compare them using your compareValues function
      return compareValues(valueA, valueB);
    }
    return 0;
  });

  // Function to filter the sortedNameData based on all applied filters
  let filteredNameData = sortedNameData.filter((name) => {
    // Check if Disregard F filter is enabled and the name has 'F' grade
    if (disregardF && ["D", "F"].includes(name.trademark)) {
      return false;
    }

    if (disregardSensitive && name.sensitivity === true) {
      return false;
    }

    if (maxCharacter && projectData && projectData.max_chars && name.name.length > projectData.max_chars) {
      return false;
    }

    // If no filters match, include the name in the filtered data
    return true;
  });

  if (loading | !projectData) {
    return <span className="loading loading-ring loading-xs"></span>;
  }

  // else if (!projectData) {
  //   return <p>Project not found.</p>;
  // }

  const getCountryFlagEmoji = (countryCode) => {
    const OFFSET = 127397;
    const codePoints = countryCode
      .toUpperCase()
      .split("")
      .map((char) => char.charCodeAt() + OFFSET);

    return String.fromCodePoint(...codePoints);
  };

  const handleAddName = async (event) => {
    event.preventDefault();
    const newName = event.target.nameInput.value;
    setManualButtonDisabled(true);
    try {
      const response = await fetch(`${host}/api/projects/${projectId}/add_name`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name: newName, countries: projectData.countries, nice_classes: projectData.nice_classes }),
      });

      if (response.ok) {
        setManualButtonDisabled(false);
        const result = await response.json();
        const nameId = result.data.nameId; // Assuming the backend returns the ID as 'nameId'
        navigate(`/projects/${projectId}/names/${nameId}`); // Redirect to the new name's page
      } else {
        console.error("Failed to add name");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="container mx-auto w-full pl-5">
      <div className="text-sm breadcrumbs pb-5">
        <ul>
          <li>
            <Link className="text-secondary" to={`/`}>
              <FontAwesomeIcon icon={faHome} size="1x" />
              <p className="pl-2 text-secondary">Home</p>
            </Link>
          </li>
          <li>
            <Link className="text-secondary" to={`/projects`}>
              <FontAwesomeIcon icon={faFolder} size="1x" />
              <p className="pl-2 text-secondary">Projects</p>
            </Link>
          </li>
          <li>
            <Link className="text-secondary" to={`/projects/${projectId}`}>
              <p className="pl-2 text-secondary">{projectId}</p>
            </Link>
          </li>
        </ul>
      </div>
      <div className="flex flex-wrap">
        <div className="w-full lg:w-1/2 p-5">
          <article className="prose ">
            <div className="flex items-center">
              <h1 className="text-4xl font-bold mb-0 mr-2 text-gray-800">
                {projectData.name} {["generate", "mutate"].includes(status) && <div className="pulse-icon inline-block ml-2"></div>}
              </h1>
            </div>
          </article>

          <div>
            <div className="mt-6">
              <p className="mb-4 text-gray-600">
                <span className="bold">Client:</span> {projectData.client}
              </p>
              <p className="mb-4 text-gray-600">
                <span className="bold text-gray-800">Business Category:</span> {projectData.category}
              </p>
              <p className="mb-4 text-gray-600">
                <span className="bold">Brand Strategy:</span> {projectData.brand_strategy}
              </p>

              <p className="mb-4 text-gray-600">
                <span className="bold">Product Description:</span> {projectData.description}
              </p>
              <p className="mb-4 text-gray-600">
                <span className="bold">Personality Attributes: </span>
                {projectData.attributes?.map((d) => (
                  <span className="badge badge-primary ml-1 mr-1 text-neutral text-" key={d}>
                    {d.toLowerCase()}
                  </span>
                ))}
              </p>

              <p className="mb-4 text-gray-600">
                <span className="bold">Differentiators: </span>
                {projectData.differentiators?.map((d) => (
                  <span className="badge badge-primary ml-1 mr-1 text-neutral" key={d}>
                    {d.toLowerCase()}
                  </span>
                ))}
              </p>
              <p className="mb-4 text-gray-600">
                <span className="bold">Maximum Characters:</span> {projectData.max_chars}
              </p>
              <p className="mb-4 text-gray-600">
                <span className="bold">Special Characters:</span> {projectData.special_chars}
              </p>
              <p className="mb-4 text-gray-600">
                <span className="bold">Local Spelling:</span> {projectData.local_spelling}
              </p>
              <p className="mb-4 text-gray-600">
                <span className="bold">Nice Classes:</span>{" "}
                {projectData.nice_classes?.map((className) => (
                  <span className="badge badge-primary ml-2 mr-2 text-neutral" key={className}>
                    {className}
                  </span>
                ))}
              </p>
              <p className="mb-4 text-gray-600">
                <span className="bold">Countries:</span>{" "}
                {projectData.countries.map((d, index) => (
                  <span key={index} className="ml-1 mr-1">
                    {getCountryFlagEmoji(d)}
                  </span>
                ))}
              </p>
              <p className="mb-4 text-gray-600">
                <span className="bold">Created On:</span> {new Date(projectData.timestamp).toLocaleDateString()}
              </p>
              <p className="mb-4 text-gray-600">
                <span className="bold ">Status:</span> {status}
              </p>
              <p className="mb-4 text-gray-600">
                <span className="bold ">Test Project:</span> {JSON.stringify(projectData.test_project)}
              </p>
            </div>
            <div className="flex justify-center">
              <div className="space-x-6 mt-5 mb-5">
                <FontAwesomeIcon icon={faChartGantt} size="2x" className="text-secondary" onClick={() => navigate(`/projects/${projectId}/stats`)} />
                <FontAwesomeIcon icon={faTrash} size="2x" className="text-secondary" onClick={() => handleDelete(projectData)} />
                <FontAwesomeIcon icon={faRefresh} size="2x" style={{ color: "white " }} onClick={() => handleClear(projectData.id)} />
              </div>
            </div>
          </div>

          <div className="divider" />

          <div>
            <div className="flex justify-center ">
              {status !== "Complete" && (
                <ul className="steps mt-5 mb-5">
                  <li className={`step ${stage.create ? "step-primary" : ""}`}>Create</li>
                  <li className={`step ${stage.generate ? "step-primary" : ""}`}>Generate</li>
                  <li className={`step ${stage.mutate ? "step-primary" : ""}`}>Mutation</li>
                  <li className={`step ${stage.complete ? "step-primary" : ""}`}>Complete</li>
                </ul>
              )}
            </div>

            <div className="flex justify-center ">
              <div className="button-container">
                <button className="btn btn-secondary text-neutral mt-4 mb-5" onClick={() => handleGenerateNames()} disabled={generateButtonDisabled}>
                  {generateButtonDisabled ? "SUBMITTED" : "GENERATE"}
                </button>
              </div>
            </div>
          </div>
          <div className="divider" />
          <div className="flex justify-center">
            <form onSubmit={handleAddName} className="w-full max-w-xs mt-5">
              <input type="text" className="input w-full" name="nameInput" placeholder="Manually enter a new name" />
              <div className="flex justify-center mt-5 mb-5">
                <button type="submit" className="btn btn-secondary text-neutral mt-5" disabled={manualButtonDisabled}>
                  {manualButtonDisabled ? "Adding Name..." : "Add Name"}
                </button>
              </div>
            </form>
          </div>

          <div className="divider" />

          <div className="flex justify-center">
            <div className="form-control">
              <label className="label cursor-pointer">
                <span className="label-text">Disregard 'Sensitive' Names</span>
                <input type="checkbox" className="toggle" checked={disregardSensitive} onChange={toggleDisregardSensitivity} />
              </label>
            </div>
            <br />
            <div className="form-control">
              <label className="label cursor-pointer">
                <span className="label-text">Disregard D & F TM's</span>
                <input type="checkbox" className="toggle" checked={disregardF} onChange={toggleDisregardF} />
              </label>
            </div>
            <br />
            <div className="form-control">
              <label className="label cursor-pointer">
                <span className="label-text">Maximum Character Length ({projectData.max_chars})</span>
                <input type="checkbox" className="toggle" checked={maxCharacter} onChange={toggleMaxCharacter} />
              </label>
            </div>
          </div>
          <div className="flex justify-center mt-5 mb-5">{<CreateGoogleSheetButton projectId={projectId} active={sortedNameData.some((row) => row.bookmarked)} />}</div>
        </div>

        {sortedNameData.length > 0 ? (
          <div className="overflow-x-auto w-full">
            <table className="table table-xs">
              <thead>
                <tr>
                  <th className="" onClick={() => handleSort("bookmarked")}>
                    Liked {sortColumn === "bookmarked" && (sortDirection === "asc" ? "↑" : "↓")}
                  </th>
                  <th className="" onClick={() => handleSort("name")}>
                    Name {sortColumn === "name" && (sortDirection === "asc" ? "↑" : "↓")}
                  </th>
                  <th className="sticky top-0 px-2 py-1 cursor-pointer" onClick={() => handleSort("overall_grade")}>
                    GRA {sortColumn === "overall_grade" && (sortDirection === "asc" ? "↑" : "↓")}
                  </th>
                  <th className="" onClick={() => handleSort("trademark")}>
                    TM {sortColumn === "trademark" && (sortDirection === "asc" ? "↑" : "↓")}
                  </th>
                  <th className="" onClick={() => handleSort("trademark_effective")}>
                    TM~{sortColumn === "trademark_effective" && (sortDirection === "asc" ? "↑" : "↓")}
                  </th>
                  <th className="" onClick={() => handleSort("uniqueness")}>
                    UNQ {sortColumn === "uniqueness" && (sortDirection === "asc" ? "↑" : "↓")}
                  </th>
                  <th className="" onClick={() => handleSort("pronounceability")}>
                    PRO{sortColumn === "pronounceability" && (sortDirection === "asc" ? "↑" : "↓")}
                  </th>
                  <th className="" onClick={() => handleSort("evocativeness")}>
                    EVO {sortColumn === "evocativeness" && (sortDirection === "asc" ? "↑" : "↓")}
                  </th>
                  <th className="" onClick={() => handleSort("sensitivity")}>
                    SEN {sortColumn === "sensitivity" && (sortDirection === "asc" ? "↑" : "↓")}
                  </th>
                  <th className="" onClick={() => handleSort("model")}>
                    PROC {sortColumn === "model" && (sortDirection === "asc" ? "↑" : "↓")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredNameData.map((name) => (
                  <tr key={name.id} className={name.trademark === "F" || name.name.length > projectData.max_chars ? "highlighted" : ""}>
                    <td>
                      <FontAwesomeIcon icon={name.bookmarked ? faHeartSolid : faHeart} size="1x" style={{ color: name.bookmarked ? "#a76ca1" : "" }} onClick={() => handleBookmarkToggle(name)} />
                    </td>

                    <td>
                      <Link className="text-secondary" to={`/projects/${name.project_id}/names/${name.id}`} onClick={handleNameClick}>
                        {name.name} {name.sensitivity === true ? <FontAwesomeIcon icon={faExclamationTriangle} style={{ color: "#a76ca1" }} size="1x" /> : ""}
                      </Link>
                    </td>
                    <td className="border px-2 py-1">{name.overall_grade === null ? "" : name.overall_grade === "" ? "-" : name.overall_grade}</td>
                    <td className="border px-2 py-1">{name.trademark === null ? "" : name.trademark === "" ? "-" : name.trademark}</td>
                    <td className="border px-2 py-1">{name.trademark_effective === null ? "" : name.trademark_effective === "" ? "-" : name.trademark_effective}</td>
                    <td className="border px-2 py-1">{name.uniqueness === null ? "" : name.uniqueness === "" ? "-" : name.uniqueness}</td>
                    <td className="border px-2 py-1">{name.pronounceability === null ? "" : name.pronounceability === "" ? "-" : name.pronounceability}</td>
                    <td className="border px-2 py-1">{name.evocativeness === null ? "" : name.evocativeness === "" ? "-" : name.evocativeness}</td>
                    <td className="border px-2 py-1">{name.sensitivity === null ? "" : name.sensitivity === "" ? "-" : name.sensitivity === true ? <FontAwesomeIcon icon={faExclamationTriangle} style={{ color: "#a76ca1" }} size="1x" /> : ""}</td>

                    <td>
                      <Link className="text-secondary" to={`/processes/${name.process}`}>
                        {name.process}
                      </Link>
                    </td>

                    <td>
                      <FontAwesomeIcon icon={faTrash} size="1x" className="text-secondary" onClick={() => handleDelete(name.id, name.name)} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <br />
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
}

export default ProjectDetail;
